<template>
  <div class="login">
    <div class="d-flex container-fluid pa-0 flex-center">
      <!--begin::Signin-->
      <div class="login-page mx-auto">
        <div class="login-box">
          <div class="login-logo">
            <router-link :to="{ name: 'Store home' }"><b>TUMAR</b></router-link>
          </div>
          <div class="card border-0">
            <div class="card-body login-card-body">
              <form class="form" novalidate="novalidate" id="kt_login_signin_form" @submit="(e) => onSubmitLogin(e)">
                <p class="login-box-msg">Sign in to start your session</p>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input type="email" class="form-control" placeholder="Email" v-model="form.email" />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input type="password" class="form-control" placeholder="Password" v-model="form.password" />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="icheck-primary">
                        <input ref="remember" type="checkbox" id="remember" checked />
                        <label for="remember"> Remember Me </label>
                      </div>
                    </div>
                    <div class="col-4">
                      <button class="btn btn-primary font-weight-bolder" style="width: 100%; font-size: 16px"
                        type="submit">
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <p class="mb-1">
                <v-list-item :ripple="false" color="white" link :to="{ name: 'forgot password' }"
                  class="px-0 font-weight-bolder">I forgot my password</v-list-item>
              </p>
              <p class="mb-0">
                <v-list-item :ripple="false" link :to="{ name: isCraftsman ? 'craftsman-register' : 'register' }"
                  class="px-0 font-weight-bolder">Register new membership</v-list-item>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: unset;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #007bff;

  &:before {
    display: none;
  }

  &:hover {
    color: #0056b3;
  }
}

.spinner.spinner-right {
  padding-right: 3.5rem !important;
}

.form {
  button {
    color: white;
  }
}
</style>

<script>
import { craftsmanMixin } from '@/mixins/Craftsman.mixin.js'

import { createNamespacedHelpers } from 'vuex'

const {
  mapState: State_auth,
  mapGetters: Getters_auth,
  mapActions: Auth_actions,
} = createNamespacedHelpers('auth')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')

export default {
  name: 'Login',
  mixins: [craftsmanMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      baseUrl: this.$store.state.auth.API_URL,
    }
  },
  computed: {
    ...State_auth(['errors']),
    ...Getters_auth(['currentUser']),

    backgroundImage() {
      return this.baseUrl + 'media/svg/illustrations/login-visual-1.svg'
    },
  },
  methods: {
    ...Auth_actions(['login', 'loginRetail', 'loginCraftsman', 'fetchCraftsmanProfile']),
    ...Actions_alerts(['addAlert']),
    onSubmitLogin(e) {
      e.preventDefault()
      const { email, password } = this.form
      const goBack = JSON.parse(localStorage.getItem('goBackLogin'));

      if (this.isCraftsman) {
        this.loginCraftsman({ email, password })
          .then((res) => {
            this.fetchCraftsmanProfile()
              .then(() => {
                if (goBack) {
                  this.$router.push({ path: goBack })
                  localStorage.removeItem('goBackLogin')
                } else {
                  this.$router.push({ path: '/admin/craftsman-profile/' })
                }
              })
          })
        .catch(() => {
          this.addAlert({ text: 'Unfortunately, the entered data is incorrect.', type: 'error' })
        })
      } else {
        this.loginRetail({
          email,
          password,
          // remember: this.$refs.remember.checked,
        })
          .then((res) => {
            if (goBack) {
              this.$router.push({ path: goBack })
              localStorage.removeItem('goBackLogin')
            } else {
              this.$router.push({ path: 'chat/' })
            }
          })
          .catch((e) => {
            const alert = {
              type: 'error',
            }
            if (e.response && e.response.status === 401) {
              this.form.email = ''
              this.form.password = ''
              alert.text = 'Wrong credentials'
              this.login({
                email,
                password,
                remember: this.$refs.remember.checked,
              })
            } else if (e.response && e.response.status === 422) {
              alert.text = 'Email is incorrect'
              this.login({
                email,
                password,
                remember: this.$refs.remember.checked,
              })
            } else {
              alert.text = 'Unexpected error'
            }
            this.addAlert(alert)
          })
      }
    },
  },
}
</script>
